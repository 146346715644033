<template>
  <div>
    <el-form
        label-width="140px"
        :model="requestConfigData"
        label-position="left"
        ref="cycleMoneyData"
    >
      <h2>1. Áp dụng cho in hóa đơn theo liên</h2>
      <el-form-item label="Chọn loại" prop="printType">
        <el-radio-group v-model="requestConfigData.statusPrint">
          <el-radio :label="true"
          >Chỉ in các khoản có đơn giá
          </el-radio
          >
          <el-radio :label="false"
          >In kèm thêm khoản chưa có đơn giá
          </el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Hiển thị thời gian " prop="printType">
        <el-radio-group v-model="requestConfigData.statusShowDateTime">
          <el-radio :label="true"
          >Có
          </el-radio
          >
          <el-radio style="margin-left: 20px" :label="false"
          >Không
          </el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-row>
      <el-col :offset="7">
        <el-button
            type="success"
            size="medium"
            :loading="loadingSaveConfig"
            mini
            @click="updatePrintConfig"
        >
          <i class="el-icon-circle-check"/>
          <span>{{ $t("button.save") }}</span>
        </el-button>

      </el-col>
    </el-row>
  </div>

</template>
<script>
import SchoolConfigService from "@/services/SchoolService/SchoolConfigService";

export default {
  name: 'SchoolConfigPrint',

  data() {
    return {
      loadingSaveConfig: false,
      requestConfigData: {
        statusPrint: undefined,
        statusShowDateTime: undefined,
      },
      initialConfigData: {
        statusPrint: undefined,
        statusShowDateTime: undefined,
      }
    }
  },

  watch: {
    "initialConfigData.statusPrint"(newValue) {
      this.requestConfigData.statusPrint = newValue;
    },

    "initialConfigData.statusShowDateTime"(newValue) {
      this.requestConfigData.statusShowDateTime = newValue;
    },
  },

  created() {
    this.initPrintData()

  },
  methods: {
    initPrintData() {
      SchoolConfigService.getConfigPrintOrder().then((res) => {
        this.initialConfigData = res?.data
      })
    },

    updatePrintConfig() {
      if (
          this.requestConfigData.statusPrint === this.initialConfigData.statusPrint &&
          this.requestConfigData.statusShowDateTime === this.initialConfigData.statusShowDateTime
      ) {
        return this.$message.error({
          showClose: true,
          message: `Cấu hình không đổi`,
          type: "danger",
        });
      } else {
        this.loadingSaveConfig = true
        const dataForm = new FormData()
        dataForm.append('statusPrint', this.requestConfigData.statusPrint);
        dataForm.append('statusShowDateTime', this.requestConfigData.statusShowDateTime);
        SchoolConfigService.saveConfigPrintOrder(dataForm).then((res) => {
              this.initialConfigData = res?.data
              return this.$message({
                showClose: true,
                message: `Lưu cấu hình thành công`,
                type: "success",
              });
            }
        )
        this.loadingSaveConfig = false
      }
    }
  }
}
</script>