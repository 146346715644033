var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "cycleMoneyData",
          attrs: {
            "label-width": "140px",
            model: _vm.requestConfigData,
            "label-position": "left",
          },
        },
        [
          _c("h2", [_vm._v("1. Áp dụng cho in hóa đơn theo liên")]),
          _c(
            "el-form-item",
            { attrs: { label: "Chọn loại", prop: "printType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.requestConfigData.statusPrint,
                    callback: function ($$v) {
                      _vm.$set(_vm.requestConfigData, "statusPrint", $$v)
                    },
                    expression: "requestConfigData.statusPrint",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v("Chỉ in các khoản có đơn giá\n        "),
                  ]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("In kèm thêm khoản chưa có đơn giá\n        "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Hiển thị thời gian ", prop: "printType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.requestConfigData.statusShowDateTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.requestConfigData, "statusShowDateTime", $$v)
                    },
                    expression: "requestConfigData.statusShowDateTime",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v("Có\n        "),
                  ]),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { label: false },
                    },
                    [_vm._v("Không\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { offset: 7 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingSaveConfig,
                    mini: "",
                  },
                  on: { click: _vm.updatePrintConfig },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }